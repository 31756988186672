import axios from 'axios';
import { inject, injectable } from 'inversify';
import jwtDecode from 'jwt-decode';
import type { IAuthService } from '../../services/IAuthService';
import ITransRESTClient from '../ITransRESTClient';
import { RESTClient } from './RESTClient';

@injectable()
export default class TransRESTClient
    extends RESTClient
    implements ITransRESTClient
{
    @inject('IAuthService')
    private authService!: IAuthService;

    private baseUrlV2 : string;

    constructor() {
        super();

        this.setBaseURL('https://wod-trans-uux56memxa-uc.a.run.app/api/v1/');
        this.baseUrlV2 = 'https://wod-trans-uux56memxa-uc.a.run.app/api/v2/';
        //this.setBaseURL('https://localhost:44342/api/v1/');
    }

    async validateOwnership(resource:string):Promise<boolean>{
        const jwt = await this.authService.getToken();
        if(!jwt) return false;
        
        const token = await this.getToken(jwt);

        if(!token) return false;

        const iss = await this.authService.getISS();

        if(!iss) return false;

        const url = this.getFullURLV2(`customer/${iss}/game/kd-sim/resource/${resource}`);

        try {
            await axios.get(url, this.getHeader(token));
            return true;
        } catch {
            return false;
        }
    }

    async processProductKey(key: string): Promise<boolean> {
        const jwt = await this.authService.getToken();
        if (jwt === null) return false;
        const iss = (jwtDecode(jwt) as any).iss;
        if (!iss) return false;

        const transToken = await this.getToken(jwt);

        if (!transToken) return false;

        const form = new FormData();
        form.set('productKey', key);

        const url = this.getFullURL(`customer/${iss}/product-key`);

        try {
            await axios.put(url, form, this.getHeader(transToken));
            return true;
        } catch {
            return false;
        }
    }

    async registerBetaEmail(email: string): Promise<boolean>{
        const form = new FormData();
        form.set('email', email);

        const url = this.getFullURL(`beta-email`);

        try {
            await axios.post(url, form);
            return true;
        } catch {
            return false;
        }
    }

    protected getFullURLV2(
        url: string,
        params: Map<string, string> = new Map<string, string>()
    ): string {
        let targetUrl = this.baseUrlV2 + url
        let currentParam = 0
        params.forEach((value: string, key: string) => {
            if (currentParam === 0) {
                targetUrl += '?'
            } else targetUrl += '&'

            targetUrl += key
            targetUrl += '='
            targetUrl += value

            currentParam++;
        })
        return targetUrl
    }
}
