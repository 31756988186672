import { inject, injectable } from "inversify";
import type ITransRESTClient from "../../rest-clients/ITransRESTClient";
import ITransService from "../ITransService";

@injectable()
export default class TranService implements ITransService{
    @inject('ITransRESTClient')
    private readonly transRESTClient! : ITransRESTClient;
    
    async registerBetaEmail(email: string): Promise<boolean> {
        return await this.transRESTClient.registerBetaEmail(email);
    }
    async processProductKey(key:string):Promise<boolean>{
        return await this.transRESTClient.processProductKey(key);
    }

    async validateOwnership(resource:string):Promise<boolean>{
        return await this.transRESTClient.validateOwnership(resource);
    }
}