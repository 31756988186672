import { useInjection } from 'inversify-react';
import React, { useEffect, useState } from 'react';
import IUIService from '../../services/IUIService';
import textData from '../../data/logintext.json';
import ITransService from '../../services/ITransService';
import styles from './activation-panel.module.scss';
import AuthPanelDialogWrapper from '../auth-panel-dialog-wrapper/auth-panel-dialog-wrapper.component';
import DownloadLauncherButton from '../download-launcher-button/download-launcher-button.component';
import { IAuthService } from '../../services/IAuthService';
import { useNavigate } from 'react-router-dom';
import classes from '../../utils/classes';

enum ActivationStage {
    NeedsLogin,
    InProgress,
    Finished,
}

export default function ActivationPanel() {
    const transService = useInjection<ITransService>('ITransService');
    const uiService = useInjection<IUIService>('IUIService');
    const authService = useInjection<IAuthService>('IAuthService');
    const [displayHeader, setDisplayHeader] = useState('Product Activated!');
    const [message, setMessage] = useState('');
    const [giftCode, setGiftCode] = useState('');
    const [email, setEmail] = useState('');
    const [activationStage, setActivationStage] = useState<ActivationStage>(
        ActivationStage.NeedsLogin
    );
    const [success, setSuccess] = useState(false);
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(window.location.search);
    const product = searchParams.get('product');

    useEffect(() => {
        let token = searchParams.get('token');
        const expire = searchParams.get('expires');
        const refresh = searchParams.get('refresh');

        if (!token || !expire || !refresh) {
            if (authService.isAuthenticated()) {
                token = authService.getTokenLocal();
            } else {
                return;
            }
        } else {
            authService.setToken({
                expires: parseInt(expire),
                jwt: token,
                refreshToken: refresh,
            });

            let finalNavString = constructNavUrl();

            navigate(finalNavString);
        }

        if (!token) return;

        const email = authService.parseISS(token);

        if (email) {
            setActivationStage(ActivationStage.InProgress);
            setEmail(email);
        }

        if (!!product) {
            console.log('validate ownership');
            transService.validateOwnership(product).then((result) => {
                if (result) {
                    setActivationStage(ActivationStage.Finished);
                    setSuccess(true);
                }
            });
        }

        function constructNavUrl() {
            let finalNavString = '/gift-code?';
            let first = true;
            searchParams.forEach((v, k) => {
                if (k === 'token' || k === 'expires' || k === 'refresh') return;
                if (!first) {
                    finalNavString += '&';
                }
                finalNavString += k + '=' + v;
            });
            return finalNavString;
        }
    }, []);

    const activateClicked = async (e: React.MouseEvent<HTMLInputElement>) => {
        e.preventDefault();
        await activateProductKey();
    };

    const activateProductKey = async () => {
        if (!giftCode) return;
        uiService.showLoading();
        const response = await transService.processProductKey(giftCode);
        if (response) {
            setMessage(textData.activateLoginFinishSuccess);
            setSuccess(true);
        } else {
            setMessage(textData.activateLoginFinishFail);
            setSuccess(false);
        }
        setActivationStage(ActivationStage.Finished);
        uiService.hideLoading();
    };

    const headerDefault = () => {
        return (
            <div className="header-container">
                <div className={styles.loginHeader}>
                    <span>Welcome, {email}</span>
                </div>
                <div className={styles.infoMessage}>
                    <span>
                        Copy and paste your invite code from your invitation
                        email below:
                    </span>
                </div>
            </div>
        );
    };

    const headerSuccess = () => {
        return (
            <div className="header-container">
                <div className={styles.loginHeader}>
                    <span>Welcome, {email}</span>
                </div>
                <div className={styles.successMessage}>
                    <span>{message}</span>
                </div>
            </div>
        );
    };

    const header = (function () {
        switch (activationStage) {
            case ActivationStage.NeedsLogin:
                return '';
            case ActivationStage.InProgress:
                return headerDefault();
            case ActivationStage.Finished:
                return headerSuccess();
        }
    })();
    const finishedBody = (function () {
        if (!success) return '';
        const rowClass = classes(styles.row, styles.successRow);
        switch (product) {
            case 'core':
                return (
                    <>
                        <div className={rowClass}>
                            <span className={styles.successRowSpan}>
                                Please enjoy and be respectful to the Dwelling.
                            </span>
                        </div>

                        <div className={rowClass}>
                            <span className={styles.successRowSpan}>
                                Please download the launcher to connect to the
                                client and travel to the dwelling.
                            </span>
                        </div>
                        <br />
                        <div className={styles.separator}></div>
                        <DownloadLauncherButton />
                    </>
                );
            default:
                return (
                    <>
                        <div className={styles.separator}></div>
                        <div className={rowClass}>
                            <span className={styles.successRowSpan}>
                                You can access your new items in the Dwelling.
                            </span>
                        </div>

                        <div className={rowClass}>
                            <span className={styles.successRowSpan}>
                                You will need the launcher to connect to the
                                client and travel to the dwelling.
                            </span>
                        </div>
                        <br />
                        <div className={styles.separator}></div>
                        <DownloadLauncherButton />
                    </>
                );
        }
    })();

    const body = (function () {
        const buttonEnabled = giftCode !== '' && giftCode;
        switch (activationStage) {
            case ActivationStage.NeedsLogin:
                return (
                    <div className={styles.row}>
                        <span>
                            Authentication required to access this page.
                        </span>
                        <br />
                        <a href="https://auth.kingdomdeath.com/login?extra=PflZeXhMIDUTZFwbl3KBVJoJ2DgaiH3KxbPfhro4363D5sIy0JRnrKAuX_Eqv31R50LQQrUAgS0_mU7VASaHuse4ctM8SfzHF4YUEs8O4fTe64ngjEKMXp1-0rg3xAmH2SiKf-0jfIX3Yg4FsRiN_-MrKDsomaIB4kiQ-TKD7GTyd0xTaVjR0myw8uDvIBWf">
                            Login here!
                        </a>
                    </div>
                );
            case ActivationStage.InProgress:
                return (
                    <React.Fragment>
                        <div className={styles.row}>
                            <input
                                type="text"
                                className={styles.activationInput}
                                value={giftCode}
                                onChange={(e) => {
                                    e.preventDefault();
                                    setGiftCode(e.target.value);
                                }}
                                placeholder="Product Key"
                            />
                        </div>

                        <div className={styles.buttonContainer}>
                            <input
                                type="button"
                                onClick={activateClicked}
                                id="wod-login-btn"
                                value="Activate Key"
                                disabled={!buttonEnabled}
                            />
                        </div>

                        <hr />

                        <DownloadLauncherButton />
                    </React.Fragment>
                );
            case ActivationStage.Finished:
                return finishedBody;
        }
    })();

    const popupClass =
        activationStage !== ActivationStage.InProgress
            ? 'wrapContent'
            : undefined;

    return (
        <AuthPanelDialogWrapper popupClass={[popupClass]}>
            <div className={styles.mainBody}>
                {header}
                {body}
            </div>
        </AuthPanelDialogWrapper>
    );
}
