import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { RootState } from '../../redux/store';
import { connect } from 'react-redux';
import ActivationPage from '../activation-page/ActivationPage';

class PageWithHeader extends React.Component<{}, {}> {
    render() {
        return (
            <React.Fragment>
                <BrowserRouter>
                    <Routes>
                        <Route path="/gift-code" element={<ActivationPage />} />
                        <Route
                            index
                            element={<Navigate to="/gift-code" replace />}
                        />
                    </Routes>
                </BrowserRouter>
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state: RootState) => ({
    isAuthorized: state.auth.jwtToken !== null,
});

export default connect(mapStateToProps)(PageWithHeader);
